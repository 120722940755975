import React, { Component } from 'react'
import Layout from '../layout'
import PrivacyPolicy from '../components/PrivacyPolicy'

import Header from '../components/Header'
import Footer from '../components/Footer'
import SEO from '../components/SEO'

class PrivacyPolicyPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title='Privacy Policy' />
        <Header />
        <PrivacyPolicy />
        <Footer />
      </Layout>
    )
  }
}

export default PrivacyPolicyPage

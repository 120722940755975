import React from 'react'
import { Section, Container } from 'bloomer'

export default () => (
  <Section>
    <Container style={{ maxWidth: 800 }}>
      <div className="terms-wrapper">
        <h1 className="legal-heading">Privacy Policy</h1>
        <p className="last-update">| Last Updated: August 12, 2019</p>
        <div className="policy-container legal-text">
          <p>
            This Privacy Policy explains what personal and other information is
            collected through our website{' '}
            <a href="http://www.sqrl.me">www.sqrl.me</a>, (the “Site”) and our
            mobile application ( collectively, “SQRL” ). Eighty4 Inc., the owner
            and operator of SQRL is committed to your right to privacy and
            security of your personal identity with respect to your right to use
            SQRL. Accordingly, we have prepared this privacy policy statement to
            describe our collection, use and disclosure of your personal
            information through the SQRL.
          </p>
          <p>
            By visiting the Site and/or using SQRL, you agree to be bound by
            this Privacy Policy and our Terms of Use. If you do not agree to the
            terms in this Privacy Policy, you may not access or use the Site
            and/or SQRL.
          </p>
          <div className="point-container">
            <h2>Collection of Personal Information</h2>
            <p>
              We use reasonable measures to maintain the security of your
              Personal Information. However, no company, organization, or online
              community, including the Company or the App, can fully eliminate
              security risks associated with personal information.
            </p>
            <ol className="single-intend dense-list composite-list">
              <li>
                <p>Information You Provide</p>
                <p>
                  In order to create an account with SQRL, we require
                  information to verify your identity as a part of the
                  registration process including:
                </p>
                <ul
                  style={{ marginTop: 10, marginBottom: 10 }}
                  className="double-intend"
                >
                  <li>Name</li>
                  <li>Email Address</li>
                  <li>Phone Number</li>
                  <li>Address</li>
                  <li>Date of Birth</li>
                  <li>Social Security Number</li>
                </ul>
                <p>
                  In some instances, for as identity verification, we may also
                  collect proof of identity documents. Examples of such
                  documents include a Government State I.D., Driver’s License,
                  or a Passport.
                </p>
                <p>
                  Information that you provide to the Company when interacting
                  with our customer support team or other team members via
                  phone, email, online form, or other correspondence.
                </p>
                <p>
                  Personal information stored with third parties that you choose
                  to provide to us, such as social media account information .
                  By linking your third-party accounts or using third party
                  sign-in services, you authorize the Company to have access to
                  this information and you agree that the Company may collect
                  and use this information in accordance with this Privacy
                  Policy.
                </p>
                <p>
                  Personal testimonial information or endorsements that you
                  voluntarily provide to us and which we may display with your
                  name on the App with your consent.
                </p>
                <p>
                  Upon receiving a request to delete your account information,
                  we will immediately disconnect all connections to your bank
                  accounts, along with all personal information that you have
                  provided upon the registration for your account. This means
                  that the account account information that you have provided is
                  no longer retrievable and that you will have to create a new
                  account in the event that, subsequent to your request to
                  delete the account, you elect to again register for the App.
                </p>
              </li>
              <li>
                <p>Device Information</p>
                <p>
                  We also collect the following information from your device
                  when you use the Services, including your mobile device when
                  you use our mobile application: your IP address, the type of
                  device you use, your device identifier, your operating system
                  version, the type of browser you use, your location, and the
                  URL that you came from and the next URL that you visit.
                </p>
              </li>
              <li>
                <p>Health Data</p>
                <p>
                  We may also collect fitness data through Apple Health Kit /
                  Google Fit. Please review the respective privacy policy for
                  information on how Apple Health Kit / Google Fit uses and
                  shares your personal information. The Privacy Policy of SQRL
                  does not apply to the collection and use of your information
                  by Apple Health Kit / Google Fit.
                </p>
              </li>
              <li>
                <p>Application Analytics</p>
                <p>
                  We use mobile analytics software to allow us to better
                  understand the functionality of our mobile application. This
                  software may record information such as how often you use the
                  application, the events that occur within the application,
                  aggregated usage, performance data, and where the application
                  was downloaded from. We may combine this automatically
                  collected log information with other information we collect
                  about you to provide and improve the Services.
                </p>
                <p>
                  Please note that Anonymous Data are used to gather general
                  statistics regarding our customers and visitors to enhance the
                  consumer experience on the App. We may also use demographic
                  data in a manner that does not identify you specifically but
                  does identify certain criteria about our users in general. For
                  example, we may inform third parties about the number of
                  registered users, the number of unique visitors, and the pages
                  most frequently browsed.
                </p>
              </li>
              <li>
                <p>Financial Data</p>
                <p>
                  Certain financial information, all of which is encrypted by
                  the App or third parties with which we collaborate, that is
                  necessary to transfer funds between your bank accounts.
                </p>
                <span>Disclaimer:</span>
                <br />
                <p>
                  We do not read your savings account balance. Therefore, the
                  amount shown as money saved in the App may not be reflective
                  of the money in your account. This information simply reflects
                  the money that you have saved from SQRL and does not attempt
                  to accurately state the total balance in your savings account.
                </p>
              </li>
              <li>
                <p>Choice Regarding Disclosure of Personal Information</p>
                <p>
                  You may choose not to provide us with any Personal
                  Information. In such an event, you can still access and use
                  some portions of the App; however, you will not be able to
                  access and use portions of the App that require your Personal
                  Information. But if you register on our App as a subscriber,
                  you must provide us with the Essential Data or you will not be
                  able to effectively use the App Services.
                </p>
              </li>
              <li>
                <p>Confidentiality and Security of Personal Information</p>
                <p>
                  Except as described in this Privacy Policy, we will not share
                  your Personal Information with third parties, unless such
                  disclosure is necessary (as determined in our sole discretion)
                  to: (a) comply with a court order or other legal process; (b)
                  protect the rights, property, and/or safety of the Company or
                  any third party; or (c) enforce these Terms of Use.
                </p>
              </li>
            </ol>
          </div>
          <div className="point-container">
            <h2>Use of Personal Information</h2>
            <p>We collect your personal information to:</p>
            <ol className="single-intend">
              <li>Provide the Services to you.</li>
              <li>Notify you about your account activity.</li>
              <li>Process transactions and collect fees.</li>
              <li>Provide customer support and resolve disputes.</li>
              <li>Verify your identity and account information.</li>
              <li>
                Identify, prevent, and report potentially prohibited,
                fraudulent, or illegal activities.
              </li>
              <li>Customize and improve your experience and the Services.</li>
              <li>
                Personal you at your telephone number by voice or SMS to
                validate the telephone number or your account.
              </li>
              <li>
                Notify you about important changes to the Services and/or ’s
                terms and conditions.
              </li>
              <li>
                Provide you with news, offers, and information about the
                Services based on your communications preferences.
              </li>
              <li>
                Any other purpose that we disclose to you in the course of
                providing the Services to you.
              </li>
            </ol>
          </div>
          <div className="point-container">
            <h2>Sharing of Personal Information</h2>
            <p>We may share your personal information as follows:</p>
            <ol className="single-intend dense-list">
              <li>
                If your transfer to your savings account fails, we may provide
                the recipient bank with details of the unsuccessful transfer.
              </li>
              <li>
                With third parties to provide, maintain, and improve the App
                including fraud prevention, identity verification, analytics and
                marketing services, and financial institutions and other
                partners that are part of the payment process. These companies
                are authorized to use your personal information only as
                necessary to provide these services to us.
              </li>
              <li>
                With third parties upon your request. For example, you may ask
                us to confirm your status as an App user with a third party.
              </li>
              <li>
                If we believe that disclosure is reasonably necessary to comply
                with any applicable law, legal process, or government request;
                to investigate or bring legal action against someone who may be
                violating our rights, our terms and conditions, or the rights of
                others; or to protect the safety and security of the App or our
                users. Also, please be advised that certain personal information
                becomes public record upon the filing of documents with the
                state or the court. For example, the corporate name, business
                address and the name of the registered agent become public
                information upon the filing of the articles of incorporation. In
                many cases, the Secretary of State will provide this information
                to third parties for a fee. The US Patent and Trademark Office
                will also make public the names and addresses of trademark
                registrants. In some states, fictitious business names,
                including the name and address of the business owner, must be
                published in a newspaper. Our privacy policy does not cover
                actions by these third parties.
              </li>
              <li>
                With a third party if we are involved in a merger, acquisition,
                or sale of all or a portion of our stock or assets. If this
                occurs, you will be notified of any changes to this Privacy
                Policy, as well as any choices you may have regarding your
                personal information.
              </li>
              <li>
                We may share aggregated Anonymous Data, as defined below, with
                third parties. Other than as stated in this Privacy Policy, SQRL
                does not sell any of your personal information to third parties
                without your consent.
              </li>
            </ol>
          </div>
          <div className="point-container">
            <h2>Service Providers</h2>
            <p>
              The use of SQRL confirms acceptance of Plaid’s{' '}
              <a href="https://plaid.com/legal/#privacy-policy">
                Privacy Policy
              </a>
              . To authenticate accounts, we’ve integrated with{' '}
              <a href="https://plaid.com/">Plaid</a>, a San Francisco- based
              financial technology ("fintech") company that builds connections
              with thousands of financial institutions. They're a trusted
              partner to companies ranging from popular fintech applications to
              Fortune 500 companies and major banks. These relationships require
              the company to have strong security controls in place, and its
              platform is regularly audited and tested to ensure its controls
              meet industry standards. For example, Plaid ensures sensitive data
              (both in- motion and at-rest) is encrypted, and regularly
              completes SOC-2 audits.
            </p>
            <p>
              When you enter your online banking credentials, Plaid establishes
              an encrypted connection with your bank. This flow was designed
              with security in mind, as an easy alternative to entering static
              account and routing numbers. Once this connection is established,
              our application receives a token. This token enables us to get the
              data we need to provide our service without direct access to your
              bank account or credentials.
            </p>
            <p>
              <a href="https://www.dwolla.com/">Dwolla</a> is a powerful
              payments platform that securely connects bank or credit union
              accounts to enable the transfer of money for any individual or
              organization in the U.S. By leveraging Dwolla’s Access API, we are
              providing an integrated payments experience for seamless funds
              transfers for our customers, while reducing the headaches
              associated with fraud, exposure of sensitive financial data, and
              complications of using third-party processors.
            </p>
          </div>
          <div className="point-container">
            <h2>Notifications</h2>
            <p>
              We provide you with a daily, weekly and monthly review of your
              progress each morning to encourage, recognize, and reward you for
              your activity. We highly recommend you to provide permission to
              send you these push notifications because they are an integral
              part of the experience. Additionally, we provide email
              notifications for any activity involving the movement of funds.
              This is a requirement of our API partners and it is the only way
              we notify you of the movements of funds, therefore disabling email
              communication is not an option we can provide.
            </p>
          </div>
          <div className="point-container">
            <h2>Updates to Privacy Policy</h2>
            <p>
              We may make changes to this Privacy Policy at any time. The date
              of the last revision appears at the top of the Policy. We will
              update the effective date of the Privacy Policy when a change is
              made. Any changes made will be made effective immediately upon
              posting.
            </p>
          </div>
          <div className="point-container">
            <h2>Questions Regarding Privacy Policy</h2>
            <p>
              The security and privacy of your personal information is very
              important to us. If you have any questions, feedback or concerns
              regarding this Privacy Policy, please contact us at&nbsp;
              <a href="mailto:support@eighty4.co">support@eighty4.co</a>.
            </p>
          </div>
        </div>
      </div>
    </Container>
  </Section>
)
